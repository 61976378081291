<script>
import DashboardOss from '@/views/dashboard/Oss.vue'
import DashboardSicantik from '@/views/dashboard/Sicantik.vue'
import DashboardSimbg from '@/views/dashboard/Simbg.vue'
import DashboardLkpm from '@/views/dashboard/Lkpm.vue'
import DashboardPengaduan from '@/views/dashboard/Pengaduan.vue'
import DashboardSurvey from '@/views/dashboard/Survey.vue'

export default {
   components: {
      DashboardOss,
      DashboardSicantik,
      DashboardSimbg,
      DashboardLkpm,
      DashboardPengaduan,
      DashboardSurvey,
   },
}
</script>

<template>
   <div>
      <DashboardOss />
      <DashboardSicantik />
      <DashboardSimbg />
      <DashboardLkpm />
      <DashboardPengaduan />
      <DashboardSurvey />
   </div>
</template>

<style scoped>

</style>
