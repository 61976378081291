<script>
import { mapState, mapActions } from 'vuex'
import StatisticCard from '@/components/cards/StatisticCard.vue'

export default {
   components: {
      StatisticCard,
   },
   computed: {
      ...mapState({
         myData: state => state.Dashboard.oss,
      }),
      getThisYear() {
         return this.$moment().year()
      },
   },
   mounted() {
      this.getOss()
   },
   methods: {
      ...mapActions({
         getOss: 'Dashboard/getOss',
      }),
   },
}
</script>

<template>
   <b-row>
      <b-col md="12" cols="12">
         <b-card class="oss-color">
            <b-row>
               <b-col md="3">
                  <b-card no-body class="mb-50">
                     <div class="rounded-top text-center oss-logo-color">
                        <b-img
                           :src="require('@/assets/images/logo/oss.png')"
                           alt="oss"
                           height="100"
                           class="img-fluid"
                        />
                     </div>
                     <b-card-body>
                        <b-card-title class="mb-25">
                           OSS RBA
                        </b-card-title>
                        <b-card-text class="mb-0">
                           <small>Online Single Submission Berbasis Risiko</small>
                        </b-card-text>
                     </b-card-body>
                  </b-card>
               </b-col>
               <b-col md="9">
                  <b-row>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.nib"
                           color="dark"
                           :statistic-title="`NIB Terbit Tahun ${getThisYear}`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.izin"
                           color="dark"
                           :statistic-title="`Izin Terbit Tahun ${getThisYear}`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.ss"
                           color="dark"
                           :statistic-title="`Sertifikat Standar Terbit Tahun ${getThisYear}`"
                        />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="XIcon"
                           :statistic="myData.syarat_belum"
                           color="danger"
                           statistic-title="Permohonan Belum Memenuhi Persyaratan"
                        />
                        <statistic-card
                           icon="ClockIcon"
                           :statistic="myData.menunggu_verifikasi"
                           color="danger"
                           statistic-title="Menunggu Verifikasi Persyaratan"
                        />
                     </b-col>
                  </b-row>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>

<style scoped>
.oss-color {
   background-color: #fee140;
   background-image: linear-gradient(315deg, #fee140 0%, #fa709a 100%);
}

.oss-logo-color {
   background-color: #ffdee9;
   background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}
</style>
