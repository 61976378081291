<script>
import { mapState, mapActions } from 'vuex'
import StatisticCard from '@/components/cards/StatisticCard.vue'

export default {
   components: {
      StatisticCard,
   },
   computed: {
      ...mapState({
         myData: state => state.Dashboard.simbg,
      }),
      getThisYear() {
         return this.$moment().year()
      },
      getThisMonth() {
         return this.$moment().format('MMMM')
      },
   },
   mounted() {
      this.getSimbg()
   },
   methods: {
      ...mapActions({
         getSimbg: 'Dashboard/getSimbg',
      }),
   },
}
</script>

<template>
   <b-row>
      <b-col md="12" cols="12">
         <b-card class="oss-color">
            <b-row>
               <b-col md="3">
                  <b-card no-body class="mb-50">
                     <div class="rounded-top text-center oss-logo-color">
                        <b-img
                           :src="require('@/assets/images/logo/simbg.png')"
                           alt="oss"
                           height="100"
                           class="img-fluid"
                        />
                     </div>
                     <b-card-body>
                        <b-card-title class="mb-25">
                           SIMBG
                        </b-card-title>
                        <b-card-text class="mb-0">
                           <small>Sistem Informasi Manajemen Bangunan Gedung</small>
                        </b-card-text>
                     </b-card-body>
                  </b-card>
               </b-col>
               <b-col md="9">
                  <b-row>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.pbg_year"
                           color="dark"
                           :statistic-title="`PBG Terbit Tahun ${getThisYear}`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.pbg_month"
                           color="dark"
                           :statistic-title="`PBG Terbit Bulan ${getThisMonth}`"
                        />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.slf_year"
                           color="dark"
                           :statistic-title="`SLF Terbit Tahun ${getThisYear}`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.slf_month"
                           color="dark"
                           :statistic-title="`SLF Terbit Bulan ${getThisMonth}`"
                        />
                     </b-col>
                  </b-row>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>

<style scoped>
.oss-color {
background-color: #21D4FD;
background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

}

.oss-logo-color {
   background-color: #ffdee9;
   background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}
</style>
