<script>
import { mapState, mapActions } from 'vuex'
import StatisticCard from '@/components/cards/StatisticCard.vue'

export default {
   components: {
      StatisticCard,
   },
   computed: {
      ...mapState({
         myData: state => state.Dashboard.lkpm,
      }),
      getThisYear() {
         return this.$moment().year()
      },
      getPrevYear() {
         return this.$moment().year() - 1
      },
      getThisMonth() {
         return this.$moment().format('MMMM')
      },
   },
   mounted() {
      this.getLkpm()
   },
   methods: {
      ...mapActions({
         getLkpm: 'Dashboard/getLkpm',
      }),
   },
}
</script>

<template>
   <b-row>
      <b-col md="12" cols="12">
         <b-card class="oss-color">
            <b-row>
               <b-col md="3">
                  <b-card no-body class="mb-50">
                     <div class="rounded-top text-center oss-logo-color">
                        <b-img
                           :src="require('@/assets/images/logo/lkpm.png')"
                           alt="oss"
                           height="100"
                           class="img-fluid"
                        />
                     </div>
                     <b-card-body>
                        <b-card-title class="mb-25">
                           LKPM Online
                        </b-card-title>
                        <b-card-text class="mb-0">
                           <small>Lapor Kegiatan Penanaman Modal</small>
                        </b-card-text>
                     </b-card-body>
                  </b-card>
               </b-col>
               <b-col md="9">
                  <b-row>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw1_prev.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan I Tahun ${getPrevYear} - (${myData.tw1_prev.jumlah})`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw2_prev.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan II Tahun ${getPrevYear} - (${myData.tw2_prev.jumlah})`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw3_prev.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan III Tahun ${getPrevYear} - (${myData.tw3_prev.jumlah})`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw4_prev.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan IV Tahun ${getPrevYear} - (${myData.tw4_prev.jumlah})`"
                        />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw1.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan I Tahun ${getThisYear} - (${myData.tw1.jumlah})`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw2.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan II Tahun ${getThisYear} - (${myData.tw2.jumlah})`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw3.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan III Tahun ${getThisYear} - (${myData.tw3.jumlah})`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.tw4.total | formatNumber"
                           color="dark"
                           :statistic-title="`Triwulan IV Tahun ${getThisYear} - (${myData.tw4.jumlah})`"
                        />
                     </b-col>
                  </b-row>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>

<style scoped>
.oss-color {
   background-color: #f4d03f;
   background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
}

.oss-logo-color {
   background-color: #ffdee9;
   background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}
</style>
