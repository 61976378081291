<script>
import { mapState, mapActions } from 'vuex'
import StatisticCard from '@/components/cards/StatisticCard.vue'

export default {
   components: {
      StatisticCard,
   },
   computed: {
      ...mapState({
         myData: state => state.Dashboard.sicantik,
      }),
      getThisYear() {
         return this.$moment().year()
      },
      getThisMonth() {
         return this.$moment().format('MMMM')
      },
   },
   mounted() {
      this.getSicantik()
   },
   methods: {
      ...mapActions({
         getSicantik: 'Dashboard/getSicantik',
      }),
   },
}
</script>

<template>
   <b-row>
      <b-col md="12" cols="12">
         <b-card class="oss-color">
            <b-row>
               <b-col md="3">
                  <b-card no-body class="mb-50">
                     <div class="rounded-top text-center oss-logo-color">
                        <b-img
                           :src="require('@/assets/images/logo/sicantik.png')"
                           alt="oss"
                           height="100"
                           class="img-fluid"
                        />
                     </div>
                     <b-card-body>
                        <b-card-title class="mb-25">
                           SICANTIK
                        </b-card-title>
                        <b-card-text class="mb-0">
                           <small>Aplikasi Cerdas Layanan Perizinan Terintegrasi untuk Publik berbasis sistem cloud</small>
                        </b-card-text>
                     </b-card-body>
                  </b-card>
               </b-col>
               <b-col md="9">
                  <b-row>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.izin_year"
                           color="dark"
                           :statistic-title="`Izin Terbit Tahun ${getThisYear}`"
                        />
                        <statistic-card
                           icon="CheckIcon"
                           :statistic="myData.izin_month"
                           color="dark"
                           :statistic-title="`Izin Terbit Bulan ${getThisMonth}`"
                        />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card
                           icon="ClockIcon"
                           :statistic="myData.izin_proses"
                           color="danger"
                           statistic-title="Izin dalam Proses"
                        />
                     </b-col>
                  </b-row>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>

<style scoped>
.oss-color {
background-color: #8EC5FC;
background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

}

.oss-logo-color {
   background-color: #ffdee9;
   background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}
</style>
